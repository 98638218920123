<template>
    <div class="transaction-page">
        <v-card color="#282F5B" class="card_container">
            <v-card-title>
                <label class="title_color--text px-5 font-weight-bold text-left text-uppercase">{{ $t(`label.history`)
                    }}</label>
            </v-card-title>
            <div>
                <div class="payment-method-list-container pt-3">
                    <div class="payment-method-list text-center">
                        <div class="payment-method-list-item" v-for="type in transactionTypes" :key="type.code">
                            <v-btn
                                    @click="searchCriteria.transactionType == type.code ? {} : changeTransactionHistoryTable(type.code, 1)"
                                    :class="searchCriteria.transactionType == type.code ? 'active white--text' : 'white--text'"
                                    height="auto"
                                    min-height="auto"
                                    class="text-center font-weight-bold my-0 pa-4 payment-method-list-item-button"
                            >
                                <label>{{ $t(`transactionType.${type.code.toLowerCase()}`) }}</label>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>

                <v-row class="px-4" justify="end">
                    <v-col class="align" cols="12">
                        <label class="font-weight-bold mr-2">{{ $t(`label.searchDate`) }}</label>
                        <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
                            <v-menu ref="menu" v-model="searchDateMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            color="secondary"
                                            :placeholder="$t(`label.from`)"
                                            append-icon="event"
                                            outlined
                                            class="input-field"
                                            hide-details
                                            dense
                                            readonly
                                            v-on="on"
                                            v-model="displaySearchDate"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        range
                                        v-model="searchDate"
                                        scrollable
                                        color="secondary"
                                        :min="minSelectableDate"
                                        :max="maxSelectableDate"
                                        :locale="language"
                                        :selected-items-text="`${searchDate[0]} - ${searchDate[1]}`"
                                        @change="reorderDateRangeValue"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                        <v-btn tile class="mx-1" width="40" height="40" color="#4650D2" style="border-radius:5px"
                               @click="changeTransactionHistoryTable(searchCriteria.transactionType, searchCriteria.pageNumber)">
                            <v-icon>search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                        class="transaction-table px-4"
                        :headers="tableHeaders"
                        :items="transactionHistory"
                        :items-per-page="this.pagination.tableRowsCount"
                        :sort-by.sync="sortData"
                        hide-default-footer
                        :no-data-text="$t(`message.noDataAvaliable`)"
                >
                    <template v-slot:body="{ items, isMobile }">
                        <tbody>
                        <template v-if="!isMobile">
                            <tr v-for="item in items" :key="item.transactionId">
                                <td class="body-2">{{ item.date | longDate }}</td>
                                <td class="body-2">{{ item.amount | currency }}</td>
                                <td
                                        class="text-uppercase body-2 transaction-status font-weight-bold"
                                        :class="item.actualStatus == 'pending' ? 'pending' : item.actualStatus == 'success' ? 'complete' : item.actualStatus == 'process' ? 'process' : 'failed'"
                                >
                                    {{ $t(`transactionStatus.${item.status}`) }}
                                </td>
                                <td class="text-uppercase body-2">
                                    {{
                                    item.type == 'bonus' || item.type == 'adjustment' || item.type == 'transfer'
                                    ? $t(`wallet.${item.fromSource.toLowerCase()}`) + " " + $t(`label.to`) + " " +
                                    $t(`wallet.${item.toSource.toLowerCase()}`)
                                    : $t(`paymentMethod.${item.methodCode.toLowerCase()}`)
                                    }}
                                </td>
                                <td class="body-2">
                                    {{ item.transactionId }}
                                    <label v-if="item.status.toLowerCase() === 'pending' && item.type === 'withdrawal'">/</label>
                                    <v-btn
                                            text
                                            v-if="item.status.toLowerCase() === 'pending' && item.type === 'withdrawal'"
                                            height="auto"
                                            class="body-2 font-weight-bold px-0"
                                            @click="confirmationCancelWithdrawal(item)"
                                            color="#FFA15C"
                                    >
                                        {{ $t(`label.cancelWithdrawal`) }}
                                    </v-btn>
                                </td>
                            </tr>
                        </template>

                        <template v-else>
                            <tr v-for="item in items" :key="item.index">
                                <td>
                                    <v-row no-gutters class="py-2">
                                        <v-col>{{ $t('label.date') }}</v-col>
                                        <v-col class="text-right">{{ item.date | longDate }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="py-2">
                                        <v-col>{{ $t('label.amount') }}</v-col>
                                        <v-col class="text-right">{{ item.amount | currency }}</v-col>
                                    </v-row>
                                    <v-row no-gutters class="py-2">
                                        <v-col>{{ $t('label.status') }}</v-col>
                                        <v-col
                                                class="text-right text-uppercase body-2 transaction-status font-weight-bold"
                                                :class="item.actualStatus == 'pending' ? 'pending' : item.actualStatus == 'success' ? 'complete' : 'failed'"
                                        >
                                            {{ $t(`transactionStatus.${item.status}`) }}
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="py-2">
                                        <v-col>{{ $t('label.method') }}</v-col>
                                        <v-col class="text-right">
                                            {{
                                            item.type == 'bonus' || item.type == 'adjustment' || item.type == 'transfer'
                                            ? $t(`wallet.${item.fromSource.toLowerCase()}`) + $t(`label.to`) +
                                            $t(`wallet.${item.toSource.toLowerCase()}`)
                                            : $t(`paymentMethod.${item.methodCode.toLowerCase()}`)
                                            }}
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="py-2">
                                        <v-col>{{ $t('label.transactionId') }}</v-col>
                                        <v-col class="text-right">{{ item.transactionId }}</v-col>
                                    </v-row>

                                    <v-row no-gutters class="py-2"
                                           v-if="item.status.toLowerCase() === 'pending' && item.type === 'withdrawal'">
                                        <v-col>
                                            <v-btn color="secondary"
                                                   class="body-2 white--text font-weight-bold px-0 text-center full-width"
                                                   @click="confirmationCancelWithdrawal(i.item)">
                                                {{ $t(`label.cancelWithdrawal`) }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </v-data-table>

                <div class="text-center py-4">
                    <v-pagination v-model="currentPage" :length="totalPage" @input="changePage"
                                  :total-visible="paginationPageLimit"></v-pagination>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script>
    import currencyFormat from '@/filters/currency-format'
    import {
        PAYMENT_TRANSACTION,
        PAYMENT_CANCEL_TRANSACTION,
        PAYMENT_RESET_CANCEL_TRANSACTION_STORE
    } from '@/store/payment.module'
    import {TRANSFER_TYPE, TRANSFER_HISTORY} from '@/store/transfer.module'
    import {SHARED, SESSION} from '@/constants/constants'
    import {locale, errorCodeHelper, uiHelper} from '@/util'

    export default {
        name: 'transaction',
        metaInfo: {
            title: `${locale.getMessage('meta.transaction_title')}`,
            titleTemplate: `%s`,
            meta: [
                {charset: 'utf-8'},
                {name: 'description', content: `${locale.getMessage('meta.transaction_description')}`},
                {name: 'keyword', content: `${locale.getMessage('meta.transaction_keyword')}`}
            ]
        },
        data: () => ({
            currencyFormatter: currencyFormat,
            language: uiHelper.getLanguage(),
            minSelectableDate: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substr(0, 10),
            maxSelectableDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            searchDate: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10), new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)],
            searchDateMenu: false,
            currentPage: 1,
            totalPage: 1,
            paginationPageLimit: 5,
            transactionHistory: [],
            transactionTypes: [],
            pagination: {},
            searchCriteria: {
                transactionType: '',
                pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
                pageSize: SHARED.DEFAULT_PAGE_SIZE
            },
            tableHeaders: [
                {
                    text: locale.getMessage('label.date'),
                    align: 'left',
                    class: 'body-1',
                    value: 'date',
                    sortable: true,
                    width: '220'
                },

                {
                    text: locale.getMessage('label.amount'),
                    align: 'left',
                    class: 'body-1',
                    value: 'amount',
                    sortable: true,
                    width: '180'
                },
                {
                    text: locale.getMessage('label.status'),
                    align: 'left',
                    class: 'body-1',
                    value: 'status',
                    sortable: true,
                    width: '150'
                },
                {
                    text: locale.getMessage('label.method'),
                    align: 'left',
                    class: 'body-1',
                    value: 'methodCode',
                    sortable: true,
                    width: '150'
                },
                {
                    text: locale.getMessage('label.transactionId'),
                    align: 'left',
                    class: 'body-1',
                    width: '300',
                    value: 'transactionId',
                    sortable: true
                }
            ],
            sortData: []
        }),
        computed: {
            displaySearchDate() {
                return this.searchDate.join(` ${locale.getMessage('label.to')} `)
            },
            trasnferTypes() {
                return this.$store.state.transfer.types
            },
            paymentTransactionHistoryPagination() {
                return this.$store.state.payment.pagination
            },
            paymentTransactionHistory() {
                return this.$store.state.payment.transactionHistory
            },
            transferTransactionHistoryPagination() {
                return this.$store.state.transfer.pagination
            },
            transferTransactionHistory() {
                return this.$store.state.transfer.history
            },
            cancelTransactionResponseComplete() {
                return this.$store.state.payment.cancelTransactionResponse.complete
            }
        },
        watch: {
            trasnferTypes() {
                this.transactionTypes.push({code: 'deposit'})
                this.transactionTypes.push({code: 'withdrawal'})
                let availableTransactionType = this.$store.state.transfer.types

                if (availableTransactionType.find(x => x.code == 'transfer') != null) {
                    this.transactionTypes.push(availableTransactionType.find(x => x.code == 'transfer'))
                }

                if (availableTransactionType.find(x => x.code == 'bonus') != null) {
                    this.transactionTypes.push(availableTransactionType.find(x => x.code == 'bonus'))
                }

                if (availableTransactionType.find(x => x.code == 'adjustment') != null) {
                    this.transactionTypes.push(availableTransactionType.find(x => x.code == 'adjustment'))
                }
                if (this.searchCriteria.transactionType == undefined) this.changeTransactionHistoryTable(this.transactionTypes[0].code, 1)
                else this.changeTransactionHistoryTable(this.searchCriteria.transactionType, 1)
            },
            paymentTransactionHistoryPagination() {
                this.pagination = {
                    pageNumber: this.$store.state.payment.pagination.pageNumber,
                    pageSize: this.$store.state.payment.pagination.pageSize,
                    lastPage: this.$store.state.payment.pagination.lastPage,
                    tableRowsCount: this.$store.state.payment.pagination.tableRowsCount
                }

                this.currentPage = this.$store.state.payment.pagination.pageNumber
                this.totalPage = this.$store.state.payment.pagination.lastPage
            },
            transferTransactionHistoryPagination() {
                this.pagination = {
                    pageNumber: this.$store.state.transfer.pagination.pageNumber,
                    pageSize: this.$store.state.transfer.pagination.pageSize,
                    lastPage: this.$store.state.transfer.pagination.lastPage,
                    tableRowsCount: this.$store.state.transfer.pagination.tableRowsCount
                }

                this.currentPage = this.$store.state.transfer.pagination.pageNumber
                this.totalPage = this.$store.state.transfer.pagination.lastPage
            },
            paymentTransactionHistory() {
                this.transactionHistory = this.$store.state.payment.transactionHistory
            },
            transferTransactionHistory() {
                this.transactionHistory = this.$store.state.transfer.history
            },
            cancelTransactionResponseComplete() {
                let response = this.$store.state.payment.cancelTransactionResponse
                if (response.complete) {
                    try {
                        this.$ga.event('Payment', `Cancel Withdrawal`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
                    } catch (err) {
                        console.log(err)
                    }
                    this.$parent.$parent.getMemberWallet()
                    this.cancelTransactionResponseCompleteDialog(response)
                    this.$store.dispatch(`${PAYMENT_RESET_CANCEL_TRANSACTION_STORE}`)
                }
            }
        },
        created() {
            this.initializeSearchCriteria()
            this.getTransactionType()
        },
        methods: {
            reorderDateRangeValue(x) {
                if (new Date(x[0]) >= new Date(x[1])) {
                    let startDate = x[1]
                    let endDate = x[0]
                    this.searchDate[0] = startDate
                    this.searchDate[1] = endDate
                }
            },
            initializeSearchCriteria() {
                if (this.$route.params.tab != '') {
                    this.searchCriteria.transactionType = this.$route.params.tab
                }
            },
            cancelTransactionResponseCompleteDialog(response) {
                let dialog = this.$parent.$parent.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.${response.action}`)

                if (response.code != 0 && !response.success) {
                    dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

                    dialog.button.push({
                        title: locale.getMessage(`label.close`),
                        action: this.$parent.$parent.closePageDialog
                    })
                } else {
                    dialog.message.push(locale.getMessage(`message.transactionCancelled`))

                    dialog.button.push({
                        title: locale.getMessage(`label.ok`),
                        action: this.$parent.$parent.closePageDialog
                    })
                }

                this.$parent.$parent.openPageDialog(dialog)
            },
            changePage(targetPage) {
                this.changeTransactionHistoryTable(this.searchCriteria.transactionType, targetPage)
            },
            getTransactionType() {
                this.$store.dispatch(`${TRANSFER_TYPE}`)
            },
            changeTransactionHistoryTable(key, pageNumber) {
                if (this.searchCriteria.transactionType != key) {
                    this.sortData = []
                }

                this.searchCriteria.transactionType = key
                this.searchCriteria.pageNumber = pageNumber
                this.transactionHistory = []
                switch (key) {
                    case SHARED.DEPOSIT.toLowerCase():
                        this.getPaymentTransactionHistory(SHARED.DEPOSIT)
                        break
                    case SHARED.WITHDRAWAL.toLowerCase():
                        this.getPaymentTransactionHistory(SHARED.WITHDRAWAL)
                        break
                    case 'bonus'.toLowerCase():
                        this.getTransferTransactionHistory(5)
                        break
                    case 'transfer'.toLowerCase():
                        this.getTransferTransactionHistory(1)
                        break
                    case 'adjustment'.toLowerCase():
                        this.getTransferTransactionHistory(2)
                        break
                }
            },
            getPaymentTransactionHistory(transactionType) {
                let obj = {
                    memberCode: localStorage.getItem(SESSION.USERNAME),
                    currency: localStorage.getItem(SESSION.CURRENCY),
                    fromDate: this.searchDate[0],
                    toDate: this.searchDate[1] + ' 23:59:59.999',
                    transType: transactionType,
                    page: this.searchCriteria.pageNumber,
                    itemsPerPage: this.searchCriteria.pageSize,
                    isPagination: true
                }
                this.$store.dispatch(`${PAYMENT_TRANSACTION}`, {obj})
            },
            getTransferTransactionHistory(transferType) {
                let obj = {
                    status: -1,
                    type: transferType,
                    fronWallet: '',
                    toWallet: '',
                    fromDate: this.searchDate[0],
                    toDate: this.searchDate[1] + ' 23:59:59.999',
                    page: this.searchCriteria.pageNumber,
                    itemsPerPage: this.searchCriteria.pageSize,
                    isPagination: true
                }
                this.$store.dispatch(`${TRANSFER_HISTORY}`, {obj})
            },

            confirmationCancelWithdrawal(data) {
                let dialog = this.$parent.$parent.initializePageDialogProperty()
                dialog.title = locale.getMessage(`message.cancelWithdrawal`)
                dialog.message.push(locale.getMessage(`message.confirmCancelWithdrawal`))
                dialog.message.push(locale.getMessage('label.transactionId') + ' : ' + data.transactionId)
                dialog.message.push(locale.getMessage('field.accountNumber') + ' : ' + locale.getMessage(`paymentMethod.${data.methodCode.toLowerCase()}`))
                dialog.message.push(locale.getMessage('field.accountName') + ' : ' + this.currencyFormatter(data.amount))

                dialog.button.push({
                    title: locale.getMessage(`label.confirm`),
                    action: () => this.cancelWithdrawal(data.transactionId)
                })

                dialog.button.push({
                    title: locale.getMessage(`label.cancel`),
                    action: this.$parent.$parent.closePageDialog,
                    class:'secondary_button'
                })

                this.$parent.$parent.openPageDialog(dialog)
            },
            cancelWithdrawal(transactionId) {
                let cancelTransactionObj = {
                    transactionId: transactionId
                }
                this.$store.dispatch(`${PAYMENT_CANCEL_TRANSACTION}`, {cancelTransactionObj})
            }
        }
    }
</script>

<style lang="scss">
    .card_container {
        margin-top: 70px;
        border-radius: 20px!important;

        &.nonBanking {
            margin-top: 0;
        }
    }

    @media (max-width: 599px) {
        .card_container {
            margin-top: 20px;
            border-radius: 20px!important;
        }
    }
    .v-data-table-header, .v-data-table__wrapper table .v-data-table-header tr{
        background-color: #2c3482 !important;
    }
    .transaction-table {
        background-color: transparent!important;
    }

    .transaction-table tr:nth-child(odd) {
        background-color: #1d223d!important;
    }

    .transaction-table tr:nth-child(even) {
        background-color: #2d3454!important;
    }

    .transaction-page {
        min-height: 650px;
    }

    .transaction-status {
        &.complete {
            color: #00C991;
        }

        &.failed {
            color: #ff0000;
        }

        &.pending {
            color: var(--v-base-base);
        }

        &.process {
            color: #FFA15C;
        }
    }

    .transaction-page-search-date-field-box {
        width: 280px;
    }
</style>
